.App {
    text-align: center;
    border: 4px solid #282c34;

    width: calc(100vw - 25px);
    /*       
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    /* overflow-y: scroll; */
}

.App .section {
}

nav {
    padding: .5em 0;
    background-color: #222;
}

ul.nav-links {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
}

.nav-links a {
    color: white;
}

/* .App-link {
  color: #61dafb;
} */

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
