.player-hand,
.dealer-hand {
  display: flex;
  margin-right: 30%;
}

.dealer-hand {
  margin-bottom: 1em;
}

.basic-trainer {
    background-color: #282c34;
    height: calc(100vh - 2em);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.basic-trainer.incorrect {
    animation: incorrectBlink 1 .5s linear forwards;
}

.basic-trainer.correct {
    animation: correctBlink 1 .5s linear forwards;
}

.basic-trainer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 4em;

    margin-top: 1em;
}

@keyframes correctBlink {
    from {
      border-color: lightgreen;
    }
    to {
      border-color: #282c34;
    }
  }
  
@keyframes incorrectBlink {
  from {
    border-color: red;
  }
  to {
    border-color: #282c34;
  }
}

