.score-bar {
    z-index: 2;
    width: 90%;}

.score-bar .bar {
    width: 100%;
    border-radius: 2px;
    background-color: #ddd;
}

.score-bar .score {
    font-size:  2em;
}
