.decision {
    position: fixed;
    z-index: 12;
    right: 0;
    padding: .5em;
    border-radius: 10px 0 0 10px;
    border: 1px solid #222;
    background: #555;
    color: white;
    cursor: pointer;
}

.decision.first {
    top: 4em;
}

.decision.second {
    top: 7em;
}

.decision.third {
    top: 10em;
}

.decision.fourth {
    top: 13em;
}

.decision.fifth {
    top: 16em;
}

.decision.sixth {
    top: 19em;
}

.decision.seventh {
    top: 22em;
}