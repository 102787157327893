.card {
    font-size: 2em;
    display: flex;
    border-radius: 5px;
    border: 1px solid #777;
    background-color: white;
    padding: 1em .5em;
    min-width: 1.2em;
    justify-content: center;
    color: #282c34;
}
