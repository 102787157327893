
.basic-strategy-chart {
    background-color: #222;
    /* #f4f4f4
    #77705e
    #48483e
    #313129 */
    color: #fdfdf1;
    padding-top: 1em;
    /* overflow-y: scroll; */
}

.basic-strategy-chart h3 {
    cursor: pointer;
}

.strategy-chart-cell.green-bg {
    background-color: #61cd14;
}
.strategy-chart-cell.red-bg {
    background-color: #ff6262;
}
.strategy-chart-cell.purple-bg {
    background-color: #b100b4;
}
.strategy-chart-cell.blue-bg {
    background-color: #0096ee;
}
.strategy-chart-cell.yellow-bg {
    background-color: #c2cc49;
}
.strategy-chart-cell.white-bg {
    background-color: #fff;
}

.strategy-chart-key .title.green-bg {
    background-color: #61cd14;
}
.strategy-chart-key .name.green-fg {
    color: #61cd14;
}

.strategy-chart-key .title.red-bg {
    background-color: #ff6262;
}
.strategy-chart-key .name.red-fg {
    color: #ff6262;
}

.strategy-chart-key .title.purple-bg {
    background-color: #b100b4;
}
.strategy-chart-key .name.purple-fg {
    color: #b100b4;
}

.strategy-chart-key .title.blue-bg {
    background-color: #0096ee;
}
.strategy-chart-key .name.blue-fg {
    color: #0096ee;
}

.strategy-chart-key .title.yellow-bg {
    background-color: #c2cc49;
}
.strategy-chart-key .name.yellow-fg {
    color: #c2cc49;
}

.strategy-chart-key .title.white-bg {
    background-color: #fff;
}
.strategy-chart-key .name.white-fg {
    color: #fff;
}

.strategy-chart-key .title {
    padding: 4px 8px;
}

.strategy-chart-key {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.key-names {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 66%;
    margin: auto;
}

div.collapsed {
    max-height: 0;
}

div.expanded {
    max-height: 1000px;
}

div.collapsing {
    animation: collapse forwards .25s linear;
}

div.expanding {
    animation: expand forwards .5s linear;
}



@keyframes collapse {
    from { max-height: 1000px }
    to { max-height: 0 }
}

@keyframes expand {
    from { max-height: 0 }
    to { max-height: 1000px }
}